import ApiService from "@/core/services/api.service";

export const GetMember = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`member/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetMemberList = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`member/list`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteMember = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`member/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateMember = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("member", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateMember = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`member/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateMemberPassword = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`member/${uuid}/password`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateAddress = (memberUuid, type, uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`${type}/${memberUuid}/address/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteAddress = (memberUuid, uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`member/${memberUuid}/address/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateAddress = (memberUuid, type, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`${type}/${memberUuid}/address`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetAddress = (memberUuid, type, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`${type}/${memberUuid}/address`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
